import { useState, useEffect } from 'react';

const useNFC = () => {
  const [isSupported, setIsSupported] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [writingStatus, setWritingStatus] = useState(null);
  const [writedData, setWritedData] = useState(null);
  const [appLoger, setAppLoger] = useState(null);
  const [nfcRecType, setNfcRecType] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);

  useEffect(() => {
    if ('NDEFReader' in window) {
      setIsSupported(true);
    } else {
      setIsSupported(false);
      setError('NFC TESTER is not supported in this browser.');
    }
  }, []);

  const startScan = async () => {
    if (!isSupported) return;

    try {
      const ndef = new window.NDEFReader();
      await ndef.scan(); // This will trigger the permission prompt if needed


      // ndef.onreading = event => {
      //   setError('');

      //   const { message, serialNumber } = event;
      //   setSerialNumber(serialNumber);
      //   if (message) {
      //     console.log('NFC message received:', message);
      //     setAppLoger('NFC message received: ' );
      //     for (const record of message.records) {
      //       switch (record.recordType) {
      //         case "text":
      //           // TODO: Read text record with record data, lang, and encoding.
      //           const textDecoder = new TextDecoder(record.encoding);
      //           setMessage(textDecoder.decode(record.data));
      //           setNfcRecType('text');
      //           break;
      //         case "url":
      //           // TODO: Read URL record with record data.
      //           const textDecoder2 = new TextDecoder(record.encoding);
      //           setMessage(textDecoder2.decode(record.data));
      //           setNfcRecType('url');
      //           break;
      //         default:
      //           // TODO: Handle other records with record data.
      //           const textDecoder3 = new TextDecoder(record.encoding);
      //           setMessage(textDecoder3.decode(record.data));
      //           setNfcRecType('default');
      //       }
      //     }
      //   } else {
      //     setMessage('No NFC message received');
      //     console.log('No NFC message received');
      //   }
      // };

      ndef.onreadingerror = () => {
        setError('NFC reading failed. Please ensure NFC is enabled on your device and that you are holding it close enough to the tag.');
      };

      ndef.onreading = event => {
          console.log("NDEF message read.");
          onReading(event);
      };
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        setError('Permission to use NFC was denied. Please enable NFC permissions in your browser settings.');
      } else if (err.name === 'NotFoundError') {
        setError('NFC TESTER not found. Ensure your device supports NFC and that it is turned on.');
      } else {
        setError(`Error starting scan: ${err.message}`);
      }
    }
  };

    const onReading = ({message, serialNumber}) => {
      setSerialNumber(serialNumber);
      for (const record of message.records) {
          setNfcRecType(record.recordType);
          switch (record.recordType) {
          case "text":
            const textDecoder = new TextDecoder(record.encoding);
            setMessage(textDecoder.decode(record.data));
            break;
          case "url":
            // TODO: Read URL record with record data.
            const textDecoder2 = new TextDecoder(record.encoding);
            setMessage(textDecoder2.decode(record.data));
            break;
          default:
            const textDecoder3 = new TextDecoder(record.encoding);
            setMessage(textDecoder3.decode(record.data));
            // TODO: Handle other records with record data.
          }
      }
    };


  // async function writeJsonToNFC(jsonData) {
   
  
  //   // Convert JSON data to a string
  //   const jsonString = JSON.stringify(jsonData);
  
  //   try {
  //     // Write JSON data to NFC tag
  //     await ndef.write({
  //       records: [
  //         {
  //           recordType: "mime",
  //           mediaType: "application/json",
  //           data: jsonString
  //         }
  //       ]
  //     });
  //     console.log("JSON data successfully written to NFC tag!");
  //   } catch (error) {
  //     console.log(`Write failed :-( try again: ${error}.`);
  //   }
  // }

  return {
    isSupported,
    error,
    message,
    writingStatus,
    startScan,
    appLoger,
    nfcRecType,
    serialNumber
  };
};

export default useNFC;
