import React from 'react';
import useNFC from '../hooks/useNFC';
import './NFCComponent.css'
import MyButton from './MyButton';

const NFCComponent = () => {
    const { isSupported, error, message, writingStatus, startScan,  appLoger, nfcRecType, serialNumber } = useNFC();

    const jsonData = {
        name: "Bangrinaldy Technology",
        age: 30,
        email: "bangrinaldy.tech@gmail.com",
        github: "rinaldycodes"
    };

    return (
        <div className='wrapper'>
            {isSupported ? (
                <div>
                    <MyButton
                        onClick={startScan}
                        type='primary'
                    >
                        Start Scan
                    </MyButton>
               
                    {serialNumber && <p>Serial Number: {serialNumber}</p>}
                    {message && <p>Message: {message}</p>}
                    <br />
                    <br />
                    <br />
                    {nfcRecType && <p>Record Type: {nfcRecType}</p>}
                    <br />
                    <br />
                    <br />
                    {appLoger && <p>App logger: {appLoger}</p>}
                    
                </div>
            ) : (
                <p>NFC is not supported in this browser.</p>
            )}
            {error && <p>Error: {error}</p>}
        </div>
    );
};

export default NFCComponent;
