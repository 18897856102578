import React from 'react';
import './MyButton.css';

export default function MyButton({ onClick, type = 'primary', children }) {
  return (
    <button type='button' className={`my-button ${type}`} onClick={onClick}>
      {children}
    </button>
  );
}
