import React from 'react';
import NFCComponent from './components/NFCComponent';
import './App.css'

const App = () => {
  return (
    <div>
      <h1 className='text-center'>NFC TESTER</h1>
      <NFCComponent />
    </div>
  );
};

export default App;
